import React, { useState, useEffect } from "react";
import { Container, Col, Form, Card, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { loginUser, userSelector, clearState } from "features/user/userSlice";
import { useHistory } from "react-router-dom";

const LoginView = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { loggedIn } = useSelector(userSelector);
  const [cardClasses, setCardClasses] = useState("card-hidden");

  // useEffect(() => {
  //   return () => {
  //     dispatch(clearState());
  //   };
  // }, []);

  useEffect(() => {
    if (loggedIn) {
      history.push("/");
    }
  }, [loggedIn]);

  useEffect(() => {
    setTimeout(function () {
      setCardClasses("");
    }, 1000);
  });

  const [inputs, setInputs] = useState({
    username: "",
    password: "",
  });
  const { username, password } = inputs;
  // const [submitted, setSubmitted] = useState(false);

  function handleChange(e) {
    const { name, value } = e.target;
    setInputs((inputs) => ({ ...inputs, [name]: value }));
  }

  function handleSubmit(e) {
    e.preventDefault();
    // setSubmitted(true);
    if (username && password) {
      // dispatch(login(username, password));
      dispatch(loginUser({ uname: username, pwd: password }));
    }
  }

  return (
    <>
      <div className="full-page section-image" data-color="black">
        <div className="content d-flex align-items-center p-0">
          <Container>
            <Col className="mx-auto" lg="4" md="8">
              <Form
                action=""
                className="form"
                method=""
                onSubmit={handleSubmit}
              >
                <Card className={"card-login " + cardClasses}>
                  <Card.Header>
                    <h3 className="header text-center">Login</h3>
                  </Card.Header>
                  <Card.Body>
                    <Card.Body>
                      <Form.Group>
                        <label>Email address</label>
                        <Form.Control
                          placeholder="Enter email"
                          type="email"
                          name="username"
                          value={username}
                          onChange={handleChange}
                        ></Form.Control>
                      </Form.Group>
                      <Form.Group>
                        <label>Password</label>
                        <Form.Control
                          placeholder="Password"
                          type="password"
                          name="password"
                          value={password}
                          onChange={handleChange}
                        ></Form.Control>
                      </Form.Group>
                      <Form.Check className="pl-0">
                        <Form.Check.Label>
                          <Form.Check.Input
                            defaultChecked
                            type="checkbox"
                          ></Form.Check.Input>
                          <span className="form-check-sign"></span>
                          Subscribe to newsletter
                        </Form.Check.Label>
                      </Form.Check>
                    </Card.Body>
                  </Card.Body>
                  <Card.Footer className="ml-auto mr-auto">
                    <Button className="btn-wd" type="submit" variant="warning">
                      Login
                    </Button>
                  </Card.Footer>
                </Card>
              </Form>
            </Col>
          </Container>
        </div>
        <div
          className="full-page-background"
          style={{
            backgroundImage:
              "url(" +
              require("assets/img/full-screen-image-2.jpg").default +
              ")",
          }}
        ></div>
      </div>

      {/* <form onSubmit={handleSubmit}>
        <input
          placeholder="First Name..."
          type="text"
          name="username"
          value={username}
          onChange={handleChange}
        />
        <input
          placeholder="Password"
          type="password"
          value={password}
          name="password"
          onChange={handleChange}
          autoComplete="off"
        />
        <input type="submit" value="Submit"></input>
      </form> */}
    </>
  );
};

export default LoginView;
