import Dashboard from "features/dashboard/Dashboard";
import ProductList from "features/product/ProductList";
import SalesTransaction from "features/sales/SalesTransaction";
import HistoryTopup from "features/topup/HistoryTopup";
import Topup from "features/topup/Topup";
import LockScreenView from "features/user/LockScreenView";
import LoginView from "features/user/LoginView";
import MutasiSaldo from "features/user/MutasiSaldo";

const routes = [
  {
    path: "/dashboard",
    layout: "/admin",
    name: "Dashboard",
    icon: "nc-icon nc-bank",
    component: Dashboard,
  },
  {
    path: "/productlist",
    layout: "/admin",
    name: "Daftar Produk",
    icon: "nc-icon nc-chart-bar-32",
    component: ProductList,
  },
  {
    path: "/transaksi",
    layout: "/admin",
    name: "Transaksi",
    icon: "nc-icon nc-chart-bar-32",
    component: SalesTransaction,
  },
  {
    path: "/mutasi",
    layout: "/admin",
    name: "Mutasi",
    icon: "nc-icon nc-chart-bar-32",
    component: MutasiSaldo,
  },
  {
    collapse: true,
    path: "/topup",
    name: "Topup",
    state: "openTopup",
    icon: "nc-icon nc-app",
    views: [
      {
        path: "/topup",
        layout: "/admin",
        name: "Request Topup",
        mini: "R",
        component: Topup,
      },
      {
        path: "/historytopup",
        layout: "/admin",
        name: "History",
        mini: "H",
        component: HistoryTopup,
      },
      {
        path: "/login-page",
        layout: "/auth",
        name: "Login Page",
        mini: "LP",
        component: LoginView,
      },
      {
        hide: true,
        path: "/lock-screen-page",
        layout: "/auth",
        name: "Lock Screen Page",
        mini: "LSP",
        component: LockScreenView,
      },
    ],
  },
];

export default routes;
