import React, { useRef, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { Container, Col, Input, FormGroup, Table, Button } from "reactstrap";
import moment from "moment";
import "moment/locale/id";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import { printService } from "../../services/printService";
import { trxService } from "../../services/trxService";
import { Form } from "react-bootstrap";

function SalesTransaction() {
  const [state, setState] = useState({
    startDate: moment().startOf("day"),
    endDate: moment().startOf("day").add(1, "days").subtract(1, "seconds"),
  });

  const [dataTrx, setDataTrx] = useState([]);

  const { control, handleSubmit } = useForm();
  const dateRangeRef = useRef(null);
  const label =
    state.startDate.format("DD MMM YYYY HH:mm") +
    " - " +
    state.endDate.format("DD MMM YYYY HH:mm");

  const handleCallback = (start, end, label) => {
    console.log(start, end, label);
    setState({ startDate: start, endDate: end });
  };

  const getData = () => {};
  const onSubmit = (data) => {
    // console.log({ data });

    var dateRange = {};
    dateRange.startDate = state.startDate.format("YYYY-MM-DD HH:mm:ss");
    dateRange.endDate = state.endDate.format("YYYY-MM-DD HH:mm:ss");

    // console.log({ dateRange });

    trxService
      .getTransaction(dateRange.startDate, data.idpelanggan)
      .then((resp) => {
        console.log(resp);
        setDataTrx(resp);
      });
  };

  const handleInputChange = (e, index) => {
    // setDisable(false);
    const { name, value } = e.target;
    const list = [...dataTrx];
    // list[index][name] = value;
    list[index] = { ...list[index], [name]: value };
    setDataTrx(list);
  };

  function handlePrint(data) {
    printService.printStruk(data);
    // console.log(data);
  }

  return (
    <>
      <div>Transaction</div>
      <Container className="bg-light border" fluid>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <FormGroup row>
            <Col md={4}>
              <DateRangePicker
                ref={dateRangeRef}
                initialSettings={{
                  timePicker: true,
                  showDropdowns: true,
                  startDate: state.startDate.toDate(),
                  endDate: state.endDate.toDate(),
                  ranges: {
                    Today: [
                      moment().startOf("day"),
                      moment()
                        .startOf("day")
                        .add(1, "days")
                        .subtract(1, "seconds"),
                    ],
                    Yesterday: [
                      moment().startOf("day").subtract(1, "days").toDate(),
                      moment().startOf("day").subtract(1, "seconds").toDate(),
                    ],
                    "Last 7 Days": [
                      moment().startOf("day").subtract(6, "days").toDate(),
                      moment()
                        .startOf("day")
                        .add(1, "days")
                        .subtract(1, "seconds"),
                    ],
                    "Last 30 Days": [
                      moment().startOf("day").subtract(29, "days").toDate(),
                      moment()
                        .startOf("day")
                        .add(1, "days")
                        .subtract(1, "seconds"),
                    ],
                    "This Month": [
                      moment().startOf("month").toDate(),
                      moment().endOf("month").toDate(),
                    ],
                    "Last Month": [
                      moment().subtract(1, "month").startOf("month").toDate(),
                      moment().subtract(1, "month").endOf("month").toDate(),
                    ],
                  },
                  locale: {
                    format: "DD/MM/YYYY HH:mm",
                  },
                }}
                onCallback={handleCallback}
              >
                <div
                  id="reportrange"
                  style={{
                    background: "#fff",
                    cursor: "pointer",
                    padding: "5px 10px",
                    border: "1px solid #ccc",
                    width: "100%",
                  }}
                >
                  <i className="fa fa-calendar"></i>&nbsp;
                  <span>{label}</span>
                  {/* <i className="fa fa-caret-down"></i> */}
                </div>
                {/* <input type="text" className="form-control" /> */}
              </DateRangePicker>
            </Col>
            <Col md={3}>
              <Controller
                name="idpelanggan"
                control={control}
                render={({ field }) => <Input {...field} />}
              />
              {/* <Input {...register("idpelanggan")} /> */}
            </Col>
            <input type="submit" />
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button type="button" onClick={getData}>
                OK
              </Button>
            </div>
          </FormGroup>
        </Form>

        <Table>
          <thead>
            <tr>
              <th>#</th>
              <th>Waktu</th>
              <th>Produk</th>
              <th>No Meter / Id Pelanggan</th>
              <th>Nama Pelanggan</th>
              <th>Token</th>
              <th>Nominal</th>
              <th>Admin</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {dataTrx.map((data, i) => {
              return (
                <tr key={data.id}>
                  <th scope="row">{i + 1}</th>
                  <td>
                    {moment(data.transDate).format("DD-MM-YYYY HH:mm:ss")}
                  </td>
                  <td>{data.productCode}</td>
                  <td>{data.destinationMobileNo}</td>
                  <td>{data.name}</td>
                  <td>{data.sn}</td>
                  <td>{data.amount}</td>
                  <td>
                    <Input
                      name="biayaadmin"
                      onChange={(e) => handleInputChange(e, i)}
                    ></Input>
                  </td>
                  <td>
                    <Button onClick={(e) => handlePrint(data)}>Cetak</Button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Container>

      <button onClick={handlePrint}>test print</button>
    </>
  );
}

export default SalesTransaction;
