import axios from "axios";
import { authHeader } from "helpers";

export const trxService = {
  getTransaction,
};

async function getTransaction(waktu, hptujuan) {
  // console.log(JSON.stringify({ waktu, hptujuan }));

  const params = {
    waktu: waktu,
    hptujuan: hptujuan,
  };

  const response = await axios.get("/api/transaction/GetInfoTransaksi", {
    params,
    headers: authHeader(),
  });
  return response.data;
}
