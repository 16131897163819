import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { userService } from "../../services/userService";

export const loginUser = createAsyncThunk(
  "users/login",
  async ({ uname, pwd }, thunkApi) => {
    const resp = await userService.login(uname, pwd);
    if (resp["code"] === "00") {
      return resp;
    } else if (resp["code"] === "13") {
      return thunkApi.rejectWithValue(resp);
    }

    // .then(
    //   (user) => {
    //     console.log("loginUser......");
    //     // dispatch(loginSuccess(user));
    //     if (user["code"] === "00") {
    //       // console.log("loginsuccess");
    //       // dispatch(loginSuccess(user));
    //       localStorage.setItem("user", JSON.stringify(user));
    //       return user;
    //     } else if (user["code"] === "13") {
    //       return thunkApi.rejectWithValue(user);
    //     }
    //   },
    //   (error) => {
    //     // dispatch(loginFailure(error.toString()));
    //     // dispatch(alertError(error.toString()));
    //     return thunkApi.rejectWithValue(error.toString());
    //   }
    // );
  }
);

export const login = (uname, pwd) => async (dispatch) => {
  dispatch(requestLogin());
  await userService.login(uname, pwd).then(
    (user) => {
      console.log("login....s");
      // dispatch(loginSuccess(user));
      if (user["code"] === "00") {
        console.log("loginsuccess");
        dispatch(loginSuccess(user));
      } else if (user["code"] === "13") {
        console.log("loginfailure");
        dispatch(loginFailure(user["message"]));
      }
    },
    (error) => {
      dispatch(loginFailure(error.toString()));
      // dispatch(alertError(error.toString()));
    }
  );
};

const userSlice = createSlice({
  name: "user",
  initialState: {
    loading: "idle",
    loggedIn: false,
    user: {},
    error: "",
    username: "",
    email: "",
  },
  reducers: {
    clearState(state) {
      state.loading = "idle";
      state.loggedIn = false;
      state.user = {};
      state.error = "";
      state.username = "";
      state.email = "";
    },
    requestLogin(state, action) {
      if (state.loading === "idle") {
        state.loading = "pending";
      }
    },
    loginSuccess(state, action) {
      if (state.loading === "pending") {
        state.loading = "idle";
        state.loggedIn = true;
        state.user = action.payload;
      }
    },
    loginFailure(state, action) {
      if (state.loading === "pending") {
        state.loading = "idle";
        state.loggedIn = false;
        state.user = {};
        state.error = action.payload;
      }
    },
  },
  extraReducers: {
    [loginUser.fulfilled]: (state, { payload }) => {
      console.log(" loginUser.fulfilled payload", payload);
      state.user = payload;
      state.loggedIn = true;
      state.error = "";
      state.loading = "idle";
      return state;
    },
    [loginUser.pending]: (state) => {
      if (state.loading === "idle") {
        state.loading = "pending";
      }
    },
    [loginUser.rejected]: (state, { payload }) => {
      if (state.loading === "pending") {
        state.loading = "idle";
        state.loggedIn = false;
        state.user = {};
        state.error = payload;
      }
    },
  },
});

export const {
  requestLogin,
  loginSuccess,
  loginFailure,
  clearState,
} = userSlice.actions;

export const userSelector = (state) => state.user;

export default userSlice.reducer;
