import React from "react";
import { Container } from "react-bootstrap";

function AuthFooter() {
  return (
    <>
      <footer className="footer position-absolute fixed-bottom">
        <Container>
          <nav>
            <ul>
              <li>
                <a
                  className="m-0"
                  href="#alpha"
                  onClick={(e) => e.preventDefault()}
                >
                  Home
                </a>
              </li>
              <li>
                <a
                  className="m-0"
                  href="#alpha"
                  onClick={(e) => e.preventDefault()}
                >
                  Company
                </a>
              </li>
              <li>
                <a
                  className="m-0"
                  href="#alpha"
                  onClick={(e) => e.preventDefault()}
                >
                  Portfolio
                </a>
              </li>
              <li>
                <a
                  className="m-0"
                  href="#alpha"
                  onClick={(e) => e.preventDefault()}
                >
                  Blog
                </a>
              </li>
            </ul>
            <p className="copyright text-center m-0">
              © {new Date().getFullYear()}{" "}
              <a href="https://www.alphatronik.id">Alphatronik</a>
            </p>
          </nav>
        </Container>
      </footer>
    </>
  );
}

export default AuthFooter;
