import axios from "axios";
import moment from "moment";
import { terbilang } from "helpers";

export const printService = {
  printStruk,
};

function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

function printStruk(data) {
  let printString = "";
  let trxData;
  console.log(data);

  if (data.productCode === "PLN") {
    trxData = JSON.parse(data.notes);
    const plnData = trxData.data;

    console.log(plnData);

    plnData.forEach((item) => {
      printString = templatePLNPascaBayarStruk(item, data.transDate);
      console.log(printString);
    });
  } else if (data.productCode === "BPJSKS") {
    trxData = JSON.parse(data.notes);
    const bpjsData = trxData.data;
    console.log(bpjsData);
    printString = templateBpjsStruk(bpjsData, trxData.ref_1, data.transDate);
    console.log(printString);
  } else if (data.productCode === "TELKOM") {
    trxData = JSON.parse(data.notes);
    const telkomData = trxData.data;
    console.log(telkomData);
    printString = templateTelkomPayStruk(
      telkomData,
      trxData.ref_1,
      data.transDate
    );
    console.log(printString);
  } else if (data.productCode === "PDAMMDN") {
    trxData = JSON.parse(data.notes);
    const pdamData = trxData.data;
    console.log(pdamData);
    printString = templatePDAMStruk(pdamData, trxData.ref_1, data.transDate);
    console.log(printString);
  } else {
    printString = templatePLNPrabayarStruk(data);
    console.log(printString);
  }

  // let sn = data["sn"].split("/");
  // let jlhbayar = data.denom + Number(data.biayaadmin);
  // let tokenInfo = {
  //   token: sn[0],
  //   namapel: sn[1],
  //   tarif: sn[2],
  //   daya: sn[3],
  //   jlhkwh: sn[4],
  //   jlhbayar: jlhbayar,
  // };

  // const info = "Informasi Hubungi Call Center 123 atau hubungi PLN Terdekat";
  // const header =
  //   //set page length in defined unit
  //   "\u001B\u0028\u0043\u0002\u0000\u00E0\u0003" +
  //   //draft printing
  //   "\u001B\u0078\u0000" +
  //   "\u001B\u0030" +
  //   "\u000E" +
  //   "\u001B\u0045" +
  //   "STRUK PEMBELIAN LISTRIK PRABAYAR".padStart(35) +
  //   "\n" +
  //   "\u001B\u0046" +
  //   "\u000A";

  // const body =
  //   "\u001B\u004D" +
  //   "NO METER/IDPEL".padEnd(14) +
  //   `: ${data.destinationMobileNo}`.padEnd(33) +
  //   "MATERAI".padEnd(15) +
  //   ": RP" +
  //   "0.00".padStart(12) +
  //   "\n" +
  //   "".padStart(47) +
  //   "PPN".padEnd(15) +
  //   ": RP" +
  //   "0.00".padStart(12) +
  //   "\n" +
  //   "NAMA".padEnd(14) +
  //   `: ${tokenInfo.namapel}`.padEnd(33) +
  //   "PPJ".padEnd(15) +
  //   ": RP" +
  //   "0.00".padStart(12) +
  //   "\n" +
  //   "TARIF/DATA".padEnd(14) +
  //   `: ${tokenInfo.tarif}/${tokenInfo.daya}`.padEnd(33) +
  //   "ANGSURAN".padEnd(15) +
  //   ": Rp" +
  //   "0.00".padStart(12) +
  //   "\n" +
  //   "NO REF".padEnd(14) +
  //   `: ${data.id}`.padEnd(33) +
  //   "RP STROOM/TOKEN".padEnd(15) +
  //   ": Rp" +
  //   `${numberWithCommas(data.denom)}`.padStart(12) +
  //   "\n" +
  //   "RP BAYAR".padEnd(14) +
  //   `: Rp..........${numberWithCommas(tokenInfo.jlhbayar)}`.padEnd(33) +
  //   "JLH KWH".padEnd(15) +
  //   ": " +
  //   tokenInfo.jlhkwh.padStart(12) +
  //   "\n" +
  //   "".padEnd(47) +
  //   "ADMIN BANK".padEnd(15) +
  //   ": Rp" +
  //   numberWithCommas(data.biayaadmin).padStart(12) +
  //   "\n\n" +
  //   "\u000E" +
  //   "\u001B\u0045" +
  //   `STROOM/TOKEN : ${tokenInfo.token}\n` +
  //   "\u001B\u0046" +
  //   "\u000A" +
  //   info.padStart(info.length + 20) +
  //   "\n\n" +
  //   "\u001B\u0045" +
  //   "TERBILANG".padEnd(14) +
  //   `: \u001B\u0034${terbilang(
  //     tokenInfo.jlhbayar
  //   ).toUpperCase()} RUPIAH\u001B\u0035\n` +
  //   "DICETAK DI".padEnd(14) +
  //   ": PPVINSPHOTO, 580;12148;12215;12222;JL PUKAT BANTING I NO 2B\n" +
  //   "TANGGAL / KODE".padStart(14) +
  //   `: ${moment(data.transDate).format(
  //     "DD-MM-YYYY HH:mm:ss"
  //   )}WIB/124819001/124819VINSPHOTO/20R388901h9b3820/AL\n` +
  //   "\u001B\u0046" +
  //   "\u000D\u000C";

  // console.log(header + body);

  const printAxios = axios.create({
    baseURL: "http://localhost:8080",
  });

  return printAxios
    .post("/api/rawprint", {
      data: printString,
    })
    .then((res) => {
      console.log(res);
    });
}

function templateTelkomPayStruk(data, ref1, trxTime) {
  const boldFont = "\u001B\u0045";
  const boldFontCancel = "\u001B\u0046";
  const carriageReturn = "\u000D";
  const formFeed = "\u000C";

  const judul = "STRUK BUKTI PEMBAYARAN TELKOM";
  const info1 = "STRUK INI MERUPAKAN BUKTI PEMBAYARAN YANG SAH, HARAP DISIMPAN";
  const info2 = "TERIMA KASIH";
  const ref1Custom =
    moment(trxTime).format("YYYYMMDDHHmmss") + ref1.padStart(18, "0");
  const header =
    //set page length in defined unit
    "\u001B\u0028\u0043\u0002\u0000\u00E0\u0003" +
    //draft printing
    "\u001B\u0078\u0000" +
    "\u001B\u0030" +
    "\u000E" +
    "\u001B\u0045" +
    `${judul.padStart(judul.length + 7)}\n\n` +
    "\u001B\u0046\u000A";

  const body =
    "\u001B\u004D" +
    `${"LAYANAN".padEnd(15)}: ${"TelkomPay".padEnd(38)}\n` +
    `${"NO.PELANGGAN".padEnd(15)}: ${data.id_pelanggan.padEnd(
      38
    )}${"PERIODE".padEnd(15)}: ${data.periode}\n` +
    `${"DIVRE / DATEL".padEnd(15)}: ${"".padEnd(38)}${"TAGIHAN".padEnd(
      15
    )}: Rp.${numberWithCommas(data.nominal).padStart(15, ".")}\n` +
    `${"NAMA PELANGGAN".padEnd(15)}: ${data.nama.padEnd(
      38
    )}${"ADMINISTRASI".padEnd(15)}: Rp.${numberWithCommas(data.admin).padStart(
      15,
      "."
    )}\n` +
    `${"NPWP".padEnd(15)}: ${"".padEnd(38)}${boldFont}${"TOTAL BAYAR".padEnd(
      15
    )}: Rp.${numberWithCommas(data.total).padStart(15, ".")}\n` +
    `${"REFF".padEnd(15)}: ${ref1Custom.padEnd(38)}\n\n` +
    `${info1.padStart(info1.length + 20)}\n` +
    `${info2.padStart(
      info2.length + 20 + info1.length / 2 - info2.length / 2
    )}\n\n` +
    `${"TERBILANG".padEnd(15)}: \u001b\u0034${terbilang(
      data.total
    ).toUpperCase()} RUPIAH\u001B\u0035\n` +
    `${"DICETAK DI".padEnd(
      15
    )}: PPVINSPHOTO, 580;12148;12215;12222;JL PUKAT BANTING I NO 2B\n` +
    `${"TANGGAL / KODE".padStart(15)}: ${moment(trxTime).format(
      "DD-MM-YYYY HH:mm:ss"
    )}WIB/124819001/124819VINSPHOTO/20R388901h9b3820/AL\n` +
    `${boldFontCancel} ${carriageReturn} ${formFeed}`;

  return header + body;
}

function templatePLNPascaBayarStruk(data, trxTime) {
  const boldFont = "\u001B\u0045";
  const boldFontCancel = "\u001B\u0046";
  const carriageReturn = "\u000D";
  const formFeed = "\u000C";
  const judul = "STRUK PEMBAYARAN TAGIHAN LISTRIK";
  const info1 = "PLN menyatakan struk ini sebagai bukti pembayaran yang sah.";
  const info2 = "Terima Kasih";
  const info3 = '"Informasi Hubungi Call Center 123 Atau Hub PLN Terdekat"';
  const header =
    //set page length in defined unit
    "\u001B\u0028\u0043\u0002\u0000\u00E0\u0003" +
    //draft printing
    "\u001B\u0078\u0000" +
    "\u001B\u0030" +
    "\u001B\u0021\u0029" +
    `${judul.padStart(judul.length + 7)}\n\n` +
    "\u001B\u0021\u0001";

  const body =
    "\u001B\u004D" +
    `${"IDPEL".padEnd(14)}: ${data.id_pelanggan.padEnd(33)}${"BL/TH".padEnd(
      15
    )}: ${data.bl_th}\n` +
    `${"NAMA".padEnd(14)}: ${data.nama.padEnd(33)}${"STAND METER".padEnd(
      15
    )}: ${data.stand_meter}\n` +
    `${"TARIF/DAYA".padEnd(14)}: ${data.tarif_daya.padEnd(33)}\n` +
    `${"RP TAG PLN".padEnd(14)}: RP ${numberWithCommas(data.tagihan).padStart(
      12
    )}\n` +
    `${boldFont}${"NO REFF".padEnd(14)}: ${data.reff.padEnd(33)}\n` +
    `${info1.padStart(info1.length + 20)}${boldFontCancel}\n` +
    `${"ADMIN BANK".padEnd(14)}: RP ${numberWithCommas(data.admin).padStart(
      12
    )}\n` +
    `${boldFont}${"TOTAL BAYAR".padEnd(14)}: RP ${numberWithCommas(
      data.total
    ).padStart(12)}${boldFontCancel}\n` +
    `${info2.padStart(info2.length + 20 + info3.length / 2 - 6)}\n` +
    `${info3.padStart(info3.length + 20)}\n\n` +
    `${boldFont}${"TERBILANG".padEnd(14)}: \u001b\u0034${terbilang(
      data.total
    ).toUpperCase()} RUPIAH\u001B\u0035\n` +
    `${"DICETAK DI".padEnd(
      14
    )}: PPVINSPHOTO, 580;12148;12215;12222;JL PUKAT BANTING I NO 2B\n` +
    `${"TANGGAL / KODE".padStart(14)}: ${moment(trxTime).format(
      "DD-MM-YYYY HH:mm:ss"
    )}WIB/124819001/124819VINSPHOTO/20R388901h9b3820/AL\n` +
    `${boldFontCancel} ${carriageReturn} ${formFeed}`;

  return header + body;
}

function templatePLNPrabayarStruk(data) {
  let sn = data["sn"].split("/");
  let jlhbayar = data.denom + Number(data.biayaadmin);
  let tokenInfo = {
    token: sn[0],
    namapel: sn[1],
    tarif: sn[2],
    daya: sn[3],
    jlhkwh: sn[4],
    jlhbayar: jlhbayar,
  };

  const info = "Informasi Hubungi Call Center 123 atau hubungi PLN Terdekat";
  const header =
    //set page length in defined unit
    "\u001B\u0028\u0043\u0002\u0000\u00E0\u0003" +
    //draft printing
    "\u001B\u0078\u0000" +
    "\u001B\u0030" +
    "\u000E" +
    "\u001B\u0045" +
    "STRUK PEMBELIAN LISTRIK PRABAYAR".padStart(35) +
    "\n" +
    "\u001B\u0046" +
    "\u000A";

  const body =
    "\u001B\u004D" +
    "NO METER/IDPEL".padEnd(14) +
    `: ${data.destinationMobileNo}`.padEnd(33) +
    "MATERAI".padEnd(15) +
    ": RP" +
    "0.00".padStart(12) +
    "\n" +
    "".padStart(47) +
    "PPN".padEnd(15) +
    ": RP" +
    "0.00".padStart(12) +
    "\n" +
    "NAMA".padEnd(14) +
    `: ${tokenInfo.namapel}`.padEnd(33) +
    "PPJ".padEnd(15) +
    ": RP" +
    "0.00".padStart(12) +
    "\n" +
    "TARIF/DATA".padEnd(14) +
    `: ${tokenInfo.tarif}/${tokenInfo.daya}`.padEnd(33) +
    "ANGSURAN".padEnd(15) +
    ": Rp" +
    "0.00".padStart(12) +
    "\n" +
    "NO REF".padEnd(14) +
    `: ${data.id}`.padEnd(33) +
    "RP STROOM/TOKEN".padEnd(15) +
    ": Rp" +
    `${numberWithCommas(data.denom)}`.padStart(12) +
    "\n" +
    "RP BAYAR".padEnd(14) +
    `: Rp..........${numberWithCommas(tokenInfo.jlhbayar)}`.padEnd(33) +
    "JLH KWH".padEnd(15) +
    ": " +
    tokenInfo.jlhkwh.padStart(12) +
    "\n" +
    "".padEnd(47) +
    "ADMIN BANK".padEnd(15) +
    ": Rp" +
    numberWithCommas(data.biayaadmin).padStart(12) +
    "\n\n" +
    "\u000E" +
    "\u001B\u0045" +
    `STROOM/TOKEN : ${tokenInfo.token}\n` +
    "\u001B\u0046" +
    "\u000A" +
    info.padStart(info.length + 20) +
    "\n\n" +
    "\u001B\u0045" +
    "TERBILANG".padEnd(14) +
    `: \u001B\u0034${terbilang(
      tokenInfo.jlhbayar
    ).toUpperCase()} RUPIAH\u001B\u0035\n` +
    "DICETAK DI".padEnd(14) +
    ": PPVINSPHOTO, 580;12148;12215;12222;JL PUKAT BANTING I NO 2B\n" +
    "TANGGAL / KODE".padStart(14) +
    `: ${moment(data.transDate).format(
      "DD-MM-YYYY HH:mm:ss"
    )}WIB/124819001/124819VINSPHOTO/20R388901h9b3820/AL\n` +
    "\u001B\u0046" +
    "\u000D\u000C";

  return header + body;
}

function templateBpjsStruk(data, ref1, trxTime) {
  const boldFont = "\u001B\u0045";
  const boldFontCancel = "\u001B\u0046";
  const carriageReturn = "\u000D";
  const formFeed = "\u000C";
  const judul = "STRUK PEMBAYARAN IURAN BPJS KESEHATAN";
  const info1 = "BPJS KESEHATAN MENYATAKAN STRUK INI SEBAGAI BUKTI YANG SAH.";
  const ref1Custom =
    moment(trxTime).format("YYYYMMDDHHmmss") + ref1.padStart(18, "0");
  const header =
    //set page length in defined unit
    "\u001B\u0028\u0043\u0002\u0000\u00E0\u0003" +
    //draft printing
    "\u001B\u0078\u0000" +
    "\u001B\u0030" +
    "\u001B\u0021\u0029" +
    `${judul.padStart(judul.length + 7)}\n\n` +
    "\u001B\u0021\u0001";

  const body =
    "\u001B\u004D" +
    `${"REF".padEnd(15)}: ${ref1Custom.padEnd(38)}${"SISA PEMBAYARAN".padEnd(
      15
    )}: Rp.${"0".padStart(12)}\n` +
    `${"TANGGAL".padEnd(15)}: ${moment(trxTime)
      .format("DD-MM-YYYY")
      .padEnd(38)}${"JUM. PERIODE".padEnd(15)}: ${data.periode.padStart(
      15
    )}\n` +
    `${"NOMOR VA".padEnd(15)}: ${data.id_pelanggan.padEnd(
      38
    )}${"JUM. TAG./PREMI".padEnd(15)}: Rp.${numberWithCommas(
      data.nominal
    ).padStart(12)}\n` +
    `${"NAMA".padEnd(15)}: ${data.nama.padEnd(38)}${"BIAYA ADMIN".padEnd(
      15
    )}: Rp.${numberWithCommas(data.admin).padStart(12)}\n` +
    `${"JUMLAH PESERTA".padEnd(15)}: ${data.jumlah_peserta
      .toString()
      .padEnd(38)}${boldFont}${"TOTAL BAYAR".padEnd(15)}: Rp.${numberWithCommas(
      data.total
    ).padStart(12)}\n` +
    `${"BPJS REFF".padEnd(15)}: ${data.reff.padEnd(38)}${boldFontCancel}\n\n` +
    `${info1.padStart(info1.length + 20)}\n\n` +
    `${boldFont}${"TERBILANG".padEnd(15)}: \u001b\u0034${terbilang(
      data.total
    ).toUpperCase()} RUPIAH\u001B\u0035\n` +
    `${"DICETAK DI".padEnd(
      15
    )}: PPVINSPHOTO, 580;12148;12215;12222;JL PUKAT BANTING I NO 2B\n` +
    `${"TANGGAL / KODE".padStart(15)}: ${moment(trxTime).format(
      "DD-MM-YYYY HH:mm:ss"
    )} WIB/124819001/124819VINSPHOTO/20R388901h9b3820/AL\n` +
    `${boldFontCancel} ${carriageReturn} ${formFeed}`;

  return header + body;
}

function templatePDAMStruk(data, ref1, trxTime) {
  const boldFont = "\u001B\u0045";
  const boldFontCancel = "\u001B\u0046";
  const carriageReturn = "\u000D";
  const formFeed = "\u000C";
  const judul = "BUKTI PEMBAYARAN REKENING AIR MINUM";
  const info1 = "PDAM MENYATAKAN STRUK INI SEBAGAI BUKTI PEMBAYARAN YANG SAH";
  const ref1Custom =
    moment(trxTime).format("YYYYMMDDHHmmss") + ref1.padStart(18, "0");
  const header =
    //set page length in defined unit
    "\u001B\u0028\u0043\u0002\u0000\u00E0\u0003" +
    //draft printing
    "\u001B\u0078\u0000" +
    "\u001B\u0030" +
    "\u001B\u0021\u0029" +
    `${judul.padStart(judul.length + 7)}\n\n` +
    "\u001B\u0021\u0001";

  const body =
    "\u001B\u004D" +
    `${"NO PELANGGAN".padEnd(15)}: 121${boldFont}${data.id_pelanggan.padEnd(
      38
    )}${boldFontCancel}${"NOMOR ACCOUNT".padEnd(15)}: 0\n` +
    `${"NAMA".padEnd(15)}: ${data.nama.padEnd(38)}${"PERIODE".padEnd(15)}: ${
      data.periode
    }\n` +
    `${"ALAMAT".padEnd(15)}: ${"".padEnd(38)}${"TAGIHAN AIR".padEnd(
      15
    )}: Rp.${numberWithCommas(data.nominal).padStart(12, ".")}\n` +
    `${"GOLONGAN TARIF".padEnd(15)}: ${"".padEnd(38)}${"DENDA".padEnd(
      15
    )}: Rp.${numberWithCommas(data.denda).padStart(12, ".")}\n` +
    `${"STAND METER".padEnd(15)}: ${data.stand_meter.padEnd(
      38
    )}${"ADMIN BANK".padEnd(15)}: Rp.${numberWithCommas(data.admin).padStart(
      12,
      "."
    )}\n` +
    `${"PEMAKAIAN".padEnd(15)}: ${" LITER".padEnd(
      38
    )}${boldFont}${"TOTAL TAGIHAN".padEnd(15)}: Rp.${numberWithCommas(
      data.total
    ).padStart(12, ".")}\n` +
    `${"REF".padEnd(15)}: ${ref1Custom.padEnd(38)}\n` +
    `${"PDAM REF".padEnd(15)}: ${data.reff.padEnd(38)}\n\n` +
    `${info1.padStart(info1.length + 20)}\n\n` +
    `${"TERBILANG".padEnd(15)}: \u001b\u0034${terbilang(
      data.total
    ).toUpperCase()} RUPIAH\u001B\u0035\n` +
    `${"DICETAK DI".padEnd(
      15
    )}: PPVINSPHOTO, 580;12148;12215;12222;JL PUKAT BANTING I NO 2B\n` +
    `${"TANGGAL / KODE".padStart(15)}: ${moment(trxTime).format(
      "DD-MM-YYYY HH:mm:ss"
    )} WIB/124819001/124819VINSPHOTO/20R388901h9b3820/AL\n` +
    `${boldFontCancel} ${carriageReturn} ${formFeed}`;

  return header + body;
}

// function printStruk(data) {
//   let sn = data["sn"].split("/");
//   let tokenInfo = {
//     token: sn[0],
//     namapel: sn[1],
//     tarif: sn[2],
//     daya: sn[3],
//     jlhkwh: sn[4],
//     jlhbayar: data.denom + data.biayaadmin
//   };

//   var idPel = "1212003110096";
//   var nama = "KOK CHIANG";
//   var periode = "JUL-2021";
//   var alamat = "JL PUKAT HARIMAU NO 145-D";
//   var nominal = 250000;
//   var gol = "RT.4";
//   var denda = 0;
//   var total = 257000;
//   var usage = "32.330 LITER";
//   var standMeter = "2.801.239 - 2.833.569";
//   var info = "PDAM MENYATAKAN STRUK INI SEBAGAI BUKTI PEMBAYARAN YANG SAH";
//   var printData =
//     "\u001B\u0028\u0043\u0002\u0000\u00E0\u0003" +
//     "\u001B\u0078\u0000" +
//     "\u001B\u0030" +
//     "\u000E" +
//     "\u001B\u0045" +
//     "BUKTI PEMBAYARAN REKENING AIR MINUM".padStart(40) +
//     "\n" +
//     "\u001B\u0046" +
//     "\u000A" +
//     "\u001B\u004D" +
//     "NO PELANGGAN".padEnd(14) +
//     ": " +
//     idPel.substring(0, 3) +
//     "\u001B\u0045" +
//     idPel.substring(3).padEnd(40) +
//     "\u001B\u0046" +
//     "NOMOR ACCOUNT".padEnd(15) +
//     ": 0\n" +
//     "NAMA".padEnd(14) +
//     ": " +
//     data.name.padEnd(43) +
//     "PERIODE".padEnd(15) +
//     `: ${periode}\n` +
//     "ALAMAT".padEnd(14) +
//     ": " +
//     alamat.padEnd(43) +
//     "TAGIHAN AIR".padEnd(15) +
//     ": Rp. " +
//     numberWithCommas(nominal).padStart(15, ".") +
//     "\n" +
//     "GOLONGAN TARIF".padEnd(14) +
//     ": " +
//     gol.padEnd(43) +
//     "DENDA".padEnd(15) +
//     ": Rp. " +
//     numberWithCommas(denda).padStart(15, ".") +
//     "\n" +
//     "STAND METER".padEnd(14) +
//     ": " +
//     standMeter.padEnd(43) +
//     "ADMIN BANK".padEnd(15) +
//     ": Rp. " +
//     numberWithCommas(data.biayaadmin).padStart(15, ".") +
//     "\n" +
//     "PEMAKAIAN".padEnd(14) +
//     ": " +
//     usage.padEnd(43) +
//     "\u001B\u0045" +
//     "TOTAL TAGIHAN".padEnd(15) +
//     ": Rp. " +
//     numberWithCommas(total).padStart(15, ".") +
//     "\n" +
//     "\u001B\u0046" +
//     "MKM REF".padEnd(14) +
//     ": \n" +
//     "PDAM REF".padEnd(14) +
//     ": \n" +
//     "\u001B\u0045" +
//     info.padStart(info.length + 14) +
//     "\n\n" +
//     "TERBILANG".padEnd(14) +
//     ": \u001B\u0034Seratus Ribu Rupiah\u001B\u0035\n" +
//     "DICETAK DI".padEnd(14) +
//     ": PPVINSPHOTO, 580;12148;12215;12222;JL PUKAT BANTING I NO 2B\n" +
//     "TANGGAL / KODE".padStart(14) +
//     ": 15-10-2021 15:23:00WIB/124819001/124819VINSPHOTO/20R388901h9b3820/AL\n" +
//     "\u001B\u0046" +
//     "\u000D\u000C";
//   return axios
//     .post("/api/rawprint", {
//       data: printData,
//     })
//     .then((res) => {
//       console.log(res);
//     });
// }
