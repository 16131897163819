import React from "react";

function HistoryTopup() {
  return (
    <>
      <div>HistoryTopup</div>
    </>
  );
}

export default HistoryTopup;
