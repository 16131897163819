import React, { useState } from "react";
import { Switch, Route } from "react-router-dom";
import routes from "routes";

import Sidebar from "components/Sidebar";
import AdminNavbar from "components/Navbars/AdminNavbar";
import AdminFooter from "components/Footers/AdminFooter";

import image3 from "assets/img/full-screen-image-3.jpg";
import FixedPlugin from "components/FixedPlugin";

function Admin() {
  const [sidebarImage, setSidebarImage] = useState(image3);
  const [sidebarBackground, setSidebarBackground] = useState("black");
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === "/admin") {
        return (
          <Route
            path={prop.layout + prop.path}
            key={key}
            component={prop.component}
          />
        );
      } else {
        return null;
      }
    });
  };

  return (
    <>
      <div className="wrapper">
        <Sidebar
          routes={routes}
          image={sidebarImage}
          background={sidebarBackground}
        />
        <div className="main-panel">
          <AdminNavbar />
          <div className="content">
            <Switch>{getRoutes(routes)}</Switch>
          </div>
          <AdminFooter />
          <div
            className="close-layer"
            onClick={() =>
              document.documentElement.classList.toggle("nav-open")
            }
          />
        </div>
      </div>
      <FixedPlugin />
    </>
  );
}

export default Admin;
