import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { Nav, Collapse } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";

import { userSelector } from "features/user/userSlice";

function Sidebar({ routes, image, background }) {
  const { user } = useSelector(userSelector);
  let location = useLocation();

  const [state, setState] = useState({});

  useEffect(() => {
    setState(getCollapseStates(routes));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getCollapseInitialState = (routes) => {
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse && getCollapseInitialState(routes[i].views)) {
        return true;
      } else if (location.pathname === routes[i].layout + routes[i].path) {
        return true;
      }
    }
    return false;
  };

  const getCollapseStates = (routes) => {
    let initialState = {};
    routes.map((prop, key) => {
      if (prop.collapse) {
        initialState = {
          [prop.state]: getCollapseInitialState(prop.views),
          ...getCollapseStates(prop.views),
          ...initialState,
        };
      }
      return null;
    });
    return initialState;
  };

  const activeRoute = (routeName) => {
    return location.pathname === routeName ? "active" : "";
  };

  const createLinks = (routes) => {
    return routes.map((prop, key) => {
      if (prop.hide) {
        return null;
      }
      if (prop.collapse) {
        var st = {};
        st[prop["state"]] = !state[prop.state];
        return (
          <Nav.Item
            className={getCollapseInitialState(prop.views) ? "active" : ""}
            as="li"
            key={key}
          >
            <Nav.Link
              className={state[prop.state] ? "collapsed" : ""}
              data-toggle="collapse"
              onClick={(e) => {
                e.preventDefault();
                setState({ ...state, ...st });
              }}
              aria-expanded={state[prop.state]}
            >
              <i className={prop.icon}></i>
              <p>
                {prop.name} <b className="caret"></b>
              </p>
            </Nav.Link>
            <Collapse in={state[prop.state]}>
              <div>
                <Nav as="ul">{createLinks(prop.views)}</Nav>
              </div>
            </Collapse>
          </Nav.Item>
        );
      }
      return (
        <Nav.Item
          className={activeRoute(prop.layout + prop.path)}
          key={key}
          as="li"
        >
          <Nav.Link to={prop.layout + prop.path} as={Link}>
            {prop.icon ? (
              <>
                <i className={prop.icon} />
                <p>{prop.name}</p>
              </>
            ) : (
              <>
                <span className="sidebar-mini">{prop.mini}</span>
                <span className="sidebar-normal">{prop.name}</span>
              </>
            )}
          </Nav.Link>
        </Nav.Item>
      );
    });
  };

  return (
    <>
      <div className="sidebar" data-color={background} data-image={image}>
        <div className="sidebar-wrapper">
          <div className="logo">
            <a
              className="simple-text logo-mini"
              href="http://www.alphatronik.id"
            >
              <div className="logo-img">
                <img
                  src={require("assets/img/logo.svg").default}
                  alt="react-logo"
                />
              </div>
            </a>

            <a
              className="simple-text logo-normal"
              href="https://www.alphatronik.id"
            >
              Alphatronik
            </a>
          </div>

          <div className="user">
            <div className="photo">
              <img
                alt="..."
                src={require("assets/img/default-avatar.png").default}
              ></img>
            </div>
            <div className="info">
              <a href="#doni">
                <span>{user.namars}</span>
              </a>
            </div>
          </div>

          <Nav as="ul">{createLinks(routes)}</Nav>
        </div>
        <div
          className="sidebar-background"
          style={{ backgroundImage: "url('" + image + "')" }}
        ></div>
      </div>
    </>
  );
}

export default Sidebar;
