import React from "react";
import { Switch, Route } from "react-router-dom";
import routes from "routes";
import AuthNavbar from "components/Navbars/AuthNavbar";
import AuthFooter from "components/Footers/AuthFooter";
import FixedPlugin from "components/FixedPlugin";

function Auth() {
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === "/auth") {
        return (
          <Route
            path={prop.layout + prop.path}
            key={key}
            component={prop.component}
          />
        );
      } else {
        return null;
      }
    });
  };

  return (
    <>
      <div className="wrapper wrapper-full-page">
        <AuthNavbar />
        <Switch>{getRoutes(routes)}</Switch>
        <AuthFooter />
      </div>
      <FixedPlugin />
    </>
  );
}

export default Auth;
