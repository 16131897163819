import React from "react";

function ProductList() {
  return (
    <>
      <div>Product List</div>
    </>
  );
}

export default ProductList;
