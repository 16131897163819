import React from "react";

function MutasiSaldo() {
  return (
    <>
      <h1>Mutasi Saldo</h1>
    </>
  );
}

export default MutasiSaldo;
